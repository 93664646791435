@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Noto Sans TC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* hide scrollbar but allow scrolling */
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}


body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-underline {
  text-decoration: underline;
}

/* use custom animation delay because the class name is dynamic */
.anim-delay50 {
  animation-delay: 50ms;
}

.anim-delay100 {
  animation-delay: 100ms;
}

.anim-delay150 {
  animation-delay: 150ms;
}

.switchOn {
  transform: translateX(2rem);
}